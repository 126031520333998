body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC,
    Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
    'Noto Serif SC', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-slider-button {
  width: 26px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: #3f45ff;
  border-radius: 100px;
  user-select: none;
}

.rv-field__children {
  display: block;
}

.rv-selector__item {
  font-size: 1.2em;
  margin-top: 12px;
  width: 100%;
  box-sizing: border-box;
}

/* 移动设备 */
@media (max-width: 768px) {
  .container {
    max-width: 768px;
    margin: 0 auto; /* 居中内容 */
  }
  .rv-card-footer {
    text-align: center !important;
  }
}

@media (min-width: 769px) {
  .container {
    max-width: 769px; /* 或适当的宽度 */
    margin: 0 auto; /* 居中内容 */
  }
}
